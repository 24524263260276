import { cssVar, defineStyleConfig } from '@chakra-ui/react';

const $bg = cssVar('tooltip-bg');

export const tooltipStyle = defineStyleConfig({
  baseStyle: {
    bg: 'black',
    [$bg.variable]: 'black',
    color: 'white',
    opacity: '0.8 !important',
    borderRadius: 'base',
    px: 2,
    py: 1,
  },
  sizes: {
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
    },
    lg: {
      fontSize: 'lg',
    },
  },
  defaultProps: {
    size: 'sm',
  },
});
