import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
);

export const menuStyle = defineMultiStyleConfig({
  baseStyle: {
    item: {
      fontSize: '12px',
      _focus: {
        bgColor: 'gray.50',
      },
    },
    list: {
      color: 'gray.500',
      fontSize: 'md',
      shadow: 'base',
    },
  },
});
