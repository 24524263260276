import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

export const tabsStyle = defineMultiStyleConfig({
  defaultProps: {
    variant: 'line',
  },
  variants: {
    line: {
      tab: {
        display: 'inline-flex',
        gap: 2,
        alignItems: 'center',
        fontSize: 'md',
        color: 'gray.400',
        p: '12px 4px 10px',
        _selected: {
          p: '12px 4px',
          color: 'blue.500',
          fontWeight: 'bold',
          shadow: 'none',
          borderBottom: '2px solid',
          borderColor: 'blue.500',
        },
      },
      tablist: {
        h: '44px',
        gap: 5,
        borderBottom: '1px solid',
        borderColor: 'gray.200',
      },
      tabpanels: {},
      tabpanel: {
        p: '24px 0',
      },
    },
  },
});
