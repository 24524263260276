import { popoverAnatomy as popover } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(popover.keys);

export const popoverStyle = defineMultiStyleConfig({
  baseStyle: {
    body: {
      padding: 0,
      bg: 'white',
    },
    footer: {},
    header: {},
    content: {
      padding: 0,
      border: '1px solid',
      borderColor: 'gray.200',
    },
    closeButton: {},
    popper: {},
    arrow: {},
  },
});
