import { defineStyleConfig } from '@chakra-ui/react';

export const linkStyle = defineStyleConfig({
  baseStyle: {
    color: 'blue.500',
    fontSize: 'md',
    _hover: {
      textDecoration: 'none',
    },
    _focusVisible: {
      boxShadow: 'none',
    },
  },
});
