import { defineStyleConfig } from '@chakra-ui/react';

export const badgeStyle = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
  },
  sizes: {
    md: {
      borderRadius: '12px',
      fontSize: 'xs',
      height: '16px',
      lineHeight: '16px',
    },
  },
  defaultProps: {
    size: 'md',
  },
});
