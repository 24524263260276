import { default as Cookies } from 'js-cookie';
import { env } from '@/constants/env';
import { GeneralError } from '@/lib/api/schema';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

type Headers = {
  Authorization?: string;
  Accept?: 'application/json';
  'Content-Type'?: 'application/json' | 'multipart/form-data';
};

const _headers: Headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export function checkToken() {
  const token = Cookies.get(env.api.TOKEN_NAME);
  if (token) {
    console.log(token);
    return true;
  }
  return false;
}

export function storeToken(token: string) {
  Cookies.set(env.api.TOKEN_NAME, token, {
    expires: 1,
    secure: true,
    sameSite: 'lax',
  });
  // _headers.Authorization = `Bearer ${token}`;
}

export function removeToken() {
  Cookies.remove(env.api.TOKEN_NAME);
  // delete _headers.Authorization;
}

function setTokenHeader() {
  const token = Cookies.get(env.api.TOKEN_NAME);
  if (token) {
    _headers.Authorization = `Bearer ${token}`;
  } else {
    delete _headers.Authorization;
  }
}

type CustomInstanceProps = {
  url: string;
  method:
    | 'GET'
    | 'POST'
    | 'PUT'
    | 'DELETE'
    | 'PATCH'
    | 'get'
    | 'post'
    | 'put'
    | 'delete'
    | 'patch';
  params?:
    | string
    | Record<string, string | string[]>
    | string[][]
    | URLSearchParams
    | undefined;
  data?: unknown;
  headers?: Headers;
  responseType?: string;
};

export const customInstance = async <T>({
  url,
  method,
  params,
  data,
  headers,
}: CustomInstanceProps): Promise<T> => {
  // TODO: リクエストヘッダの設定方法は要検討
  setTokenHeader();

  let convertedParams:
    | string
    | Record<string, string>
    | string[][]
    | URLSearchParams
    | undefined = undefined;
  if (params) {
    if (typeof params === 'object') {
      if (params instanceof URLSearchParams || Array.isArray(params)) {
        convertedParams = params;
      } else {
        convertedParams = {};
        for (const key in params) {
          const values = params[key];
          convertedParams[key] = Array.isArray(values)
            ? values.join(',')
            : values;
        }
      }
    } else {
      convertedParams = params;
    }
  }
  const requestHeaders = { ..._headers, ...headers };
  if (requestHeaders['Content-Type'] === 'multipart/form-data') {
    delete requestHeaders['Content-Type'];
  }
  const response = await fetch(
    `${env.api.BASE_URL}${url}${
      params ? `?${new URLSearchParams(convertedParams)}` : ''
    }`,
    {
      method,
      headers: requestHeaders,
      ...(data
        ? { body: data instanceof FormData ? data : JSON.stringify(data) }
        : {}),
    },
  );
  const json = await response.json();
  if (!response.ok || response.status >= 400) {
    console.log('customInstance response error', json);
    throw json;
  }

  return json;
};

export default customInstance;

export const apiErrorRoutingHandler = (
  router: AppRouterInstance,
  error: GeneralError,
) => {
  if (error.status === 401) {
    removeToken();
    localStorage.removeItem(env.localStorage.PERMISSION_KEY);
    localStorage.removeItem(env.localStorage.OPTION_KEY);
    router.push('/auth/login');
    return true;
  }

  return false;
};
