import { pinInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  pinInputAnatomy.keys,
);

export const pininputStyle = defineMultiStyleConfig({
  baseStyle: {
    field: {
      fontSize: '12px',
      w: '40px',
      h: '52px',
      borderColor: 'gray.200',
      borderRadius: 'md',
      _focus: {
        bgColor: 'gray.50',
      },
    },
  },
});
