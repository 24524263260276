import { extendTheme } from '@chakra-ui/react';

import { alertStyle } from './alert.style';
import { badgeStyle } from './badge.style';
import { buttonStyle } from './button.style';
import { checkboxStyle } from './checkbox.style';
import { formStyle } from './form.style';
import { inputStyle } from './input.style';
import { linkStyle } from './link.style';
import { menuStyle } from './menu.style';
import { modalStyle } from './modal.style';
import { popoverStyle } from './popover.styles';
import { pininputStyle } from './pininput.style';
import { radioStyle } from './radio.style';
import { switchStyle } from './switch.style';
import { tableStyle } from './table.style';
import { tabsStyle } from './tabs.style';
import { tagStyle } from './tag.style';
import { textStyle } from './text.style';
import { textareaStyle } from './textarea.style';
import { tooltipStyle } from './tooltip.style';

import { colors, fontSizes, fontWeights, radii } from './custom.theme';

export const theme = extendTheme({
  components: {
    Alert: alertStyle,
    Badge: badgeStyle,
    Button: buttonStyle,
    Form: formStyle,
    Input: inputStyle,
    Checkbox: checkboxStyle,
    Radio: radioStyle,
    Switch: switchStyle,
    Tag: tagStyle,
    Menu: menuStyle,
    Popover: popoverStyle,
    Pininput: pininputStyle,
    Table: tableStyle,
    Tabs: tabsStyle,
    Modal: modalStyle,
    Text: textStyle,
    Textarea: textareaStyle,
    Link: linkStyle,
    Tooltip: tooltipStyle,
  },
  colors,
  fontSizes,
  fontWeights,
  radii,
  config: {
    cssVarPrefix: '',
  },
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '1024px',
    xl: '1440px',
  },
  fonts: {
    body: `'Hiragino Sans', 'Noto Sans JP', sans-serif`,
  },
  styles: {
    global: {
      body: {
        color: 'black',
        backgroundColor: 'white',
      },
    },
  },
});
