import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  radioAnatomy.keys,
);

export const radioStyle = defineMultiStyleConfig({
  baseStyle: {
    control: {
      border: '1px solid',
      borderColor: 'gray.200',
      backgroundColor: 'white',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      _checked: {
        borderColor: 'gray.200',
        _before: {
          width: '6px',
          height: '6px',
          borderRadius: '50%',
          backgroundColor: 'white',
        },
        _disabled: {
          backgroundColor: 'gray.300',
          color: 'gray.200',
          _before: {
            backgroundColor: 'gray.100',
          },
        },
      },
      _disabled: {
        cursor: 'default',
        borderColor: 'gray.200',
        _checked: {
          color: 'gray.100',
        },
      },
    },
    label: {
      color: 'gray.500',
      fontWeight: 400,
      _disabled: {
        cursor: 'default',
        opacity: 1,
      },
    },
  },
});
