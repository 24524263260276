import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  alertAnatomy.keys,
);

export const alertStyle = defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    custom: {
      container: {
        backgroundColor: '#909',
        border: '1px solid #00f',
      },
      title: {},
      description: {},
      icon: {},
      spinner: {},
    },
  },
  sizes: {},
  defaultProps: {
    variant: 'custom',
  },
});
