export const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  blue: {
    50: '#f5f9ff',
    100: '#eaf3ff',
    200: '#badcff',
    300: '#97c5fb',
    400: '#64a8f9',
    500: '#318bf7',
  },
  gray: {
    50: '#f5f6f8',
    100: '#eaecf0',
    200: '#d7dae2',
    300: '#a6a7af',
    400: '#7c7e88',
    500: '#4d4f5c',
  },
  red: {
    100: '#fbf1f0',
    500: '#eb4d38',
  },
  green: {
    100: '#e4f5ef',
    500: '#22ad7f',
  },
  cyan: {
    100: '#e3f5f8',
    500: '#1fa8c4',
  },
  pink: {
    100: '#fbe5f0',
    500: '#e0358c',
  },
  purple: {
    100: '',
    500: '',
  },
  orange: {
    100: '#fef4ed',
    500: '#f78f43',
  },
  yellow: {
    100: '',
    500: '',
  },
};

export const fontSizes = {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  xl: '24px',
};

export const fontWeights = {
  normal: 'normal',
  bold: 'bold',
};

export const radii = {
  // xs: '2px',
  // sm: '4px',
  md: '4px',
  xl: '72px',
};

export const shadows = {
  base: '0 3px 12px #00000021',
};

// fontHeights: {},
// letterSpacings: {},
// sizes: {
//   xs: '',
//   sm: '',
//   md: '',
//   lg: '',
// },
// zIndices: {},
