import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tagAnatomy.keys,
);

const getSubtleTagStyles = (colorScheme: string) => {
  switch (colorScheme) {
    case 'gray':
      return {
        bg: 'gray.200',
        color: 'gray.500',
      };
    case 'green':
      return {
        bg: 'green.100',
        color: 'cyan.500',
      };
    default:
      return {
        bg: `${colorScheme}.100`,
        color: `${colorScheme}.500`,
      };
  }
};

export const tagStyle = defineMultiStyleConfig({
  variants: {
    subtle: (props) => ({
      container: getSubtleTagStyles(props.colorScheme),
    }),
    solid: {
      container: {
        blue: {
          color: 'white',
          bgColor: 'blue.500',
        },
      },
    },
    outline: {
      container: {
        gray: {
          shadow: `0 0 0 1px ${'gray.200'}`,
          color: 'gray.400',
        },
        blue: {
          color: 'blue.500',
          shadow: `0 0 0 1px ${'blue.200'}`,
        },
      },
    },
  },
  sizes: {
    sm: {
      container: {
        borderRadius: 'xl',
        fontSize: 'xs',
        px: '8px',
        minH: '14px',
      },
    },
    md: {
      container: {
        borderRadius: 'xl',
        fontSize: 'sm',
        px: '12px',
        minH: '20px',
      },
    },
    filterTag: {
      container: {
        borderRadius: 'xl',
        fontSize: 'sm',
        px: '12px',
        h: '28px',
      },
    },
  },
  defaultProps: {
    variant: 'subtle',
    size: 'md',
  },
});
