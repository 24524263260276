import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  formAnatomy.keys,
);

export const formStyle = defineMultiStyleConfig({
  baseStyle: {
    container: {
      fontSize: 'md',
      label: {
        fontWeight: 'bold',
        pb: 0,
        m: 0,
      },
    },
    requiredIndicator: {},
    helperText: {},
  },
});
