import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys,
);

export const checkboxStyle = defineMultiStyleConfig({
  baseStyle: {
    label: {
      color: 'gray.500',
    },
    control: {
      borderRadius: '4px',
      border: '1px solid',
      borderColor: 'gray.200',
      color: 'white',
      _checked: {
        borderColor: 'gray.200',
        color: 'white',
        _hover: {
          bgColor: 'blue.500',
          borderColor: 'gray.200',
        },
        _disabled: {
          borderColor: 'gray.200',
          bgColor: 'blue.100',
          color: 'white',
        },
      },
      _indeterminate: {
        borderColor: 'gray.200',
        color: 'white',
      },
      _disabled: {
        borderColor: 'gray.200',
        bgColor: 'gray.100',
        color: 'white',
      },
    },
  },
});
